import { useReorder } from '@wk93/socommerce-sdk';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import Modal from '../utils/Modal';

const ReorderWrapper: React.FC = ({ children }) => {
  const { order, cancelOrder } = useReorder();
  const [showModal, setShow] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (
      order &&
      window.location.pathname !== '/reorder' &&
      window.location.pathname !== '/success' &&
      window.location.pathname !== '/order'
    ) {
      setShow(true);
    }
  }, [order, router.pathname]);

  return (
    <>
      <Modal
        show={showModal}
        title='Niedokończone zamówienie'
        description={
          <>
            Wystąpił problem podczas składania zamówienia, czy chciałbyś je
            dokończyć?
          </>
        }
        type='info'
      >
        <Link href='/reorder'>
          <button
            onClick={() => setShow(false)}
            type='submit'
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-400 text-base font-medium text-white hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
          >
            Tak
          </button>
        </Link>
        <button
          onClick={() => {
            cancelOrder();
            setShow(false);
          }}
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-white text-base font-medium text-orange-400 hover:bg-gray-100  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
        >
          Nie
        </button>
      </Modal>
      {children}
    </>
  );
};

export default ReorderWrapper;
