import React from 'react';
import LoadingIcon from './LoadingIcon';

interface Props {
  inline?: boolean;
  absolute?: boolean;
}

const Loading: React.FC<Props> = ({ inline, absolute }) => (
  <div
    className={
      absolute
        ? 'py-4 flex justify-center bg-opacity-50 bg-white z-20 fixed inset-0'
        : inline
        ? 'py-4 flex justify-center'
        : 'h-screen flex'
    }
  >
    <LoadingIcon
      className={`h-8 w-8 text-orange-400 ${
        inline ? '' : 'fixed top-1/2 left-1/2 -ml-4 -mt-4'
      }`}
    />
  </div>
);

export default Loading;
