import type { AppProps } from "next/app";
import "hamburgers/dist/hamburgers.css";
import "tailwindcss/tailwind.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css";
import "./app.css";
import Head from "next/head";
import { SocommerceProvider } from "@wk93/socommerce-sdk";
import RouterWrapper from "../components/wrappers/RouterWrapper";
import ReorderWrapper from "../components/wrappers/ReoderWrapper";
import Script from "next/script";

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <SocommerceProvider
        url={`${process.env.NEXT_PUBLIC_API_URL}/public`}
        currency="PLN"
      >
        <RouterWrapper>
          <ReorderWrapper>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <meta
                name="p:domain_verify"
                content="7f86fcb14371b9a5f0a18faa129ae24d"
              />
            </Head>
            <Script id="gtm">
              {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-56B7BHV');`}
            </Script>
            <Script
              id="cookie-script"
              type="text/javascript"
              charSet="UTF-8"
              src="//cdn.cookie-script.com/s/64530e8bb1418d091b8dab28d3f43389.js"
            >
            </Script>
            <Component {...pageProps} />
          </ReorderWrapper>
        </RouterWrapper>
      </SocommerceProvider>
    </>
  );
};

export default MyApp;
